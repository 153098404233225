import React, {useEffect, useState} from 'react';
import {doc, getDoc, setDoc, addDoc, collection, 
        onSnapshot, query, deleteDoc, updateDoc, 
        arrayUnion, FieldValue, serverTimestamp, 
        getDocs, orderBy, startAt, endAt, where, FieldPath, limit} from "firebase/firestore";
import {useAuth} from "../context/authcontext";
import {db} from "../components/firebase";



export const Actualizar = async(feedback) =>{
        
        const iddoc = feedback.id
        const docRef = doc(db, "Ordenes", iddoc)
        await updateDoc(docRef, {Calificacion: feedback.Calificacion, Fecha_calificacion: feedback.Fecha_calificacion})
}

export const ActualizarFirma = async(cifrado, id) =>{
        
        const iddoc = id
        const docRef = doc(db, "Ordenes", iddoc)
        await updateDoc(docRef, {Firma: cifrado})
        //console.log({Firma:cifrado})
}       

export const AddOrders = async(ord, docRef) =>{
     
        try{
          await setDoc(docRef, ord)
        }catch(error){
        //   SlackErrorHandle(`${new Date()}: service.peadoby.com.ar -> Error en agregar orden -> ${error} -> Orden: ${ord}`)
        } 
  }

export const GetCategorias  = async() =>{
        let cat = []
        const querySnapshot = await getDocs(collection(db, "ListaPrecios"));
        querySnapshot.forEach((doc) => {
                const d = doc.data();
                const i = {'Grupo': doc.id};
                cat.push({...d, ...i});
        });
        return cat
}

export const GetTalleres = async() =>{
        let talleres = []
        const docRef = query(collection(db, 'Usuarios'));
        const querySnapshot = await getDocs(docRef)
        querySnapshot.forEach((doc) => {
        if (!doc.data().Taller || !doc.data().Taller.startsWith('Goldmund')) {
                talleres.push(doc.data());
              }
        });
        return talleres
}

export const GetPerfil = (id) => {
        let unsubscribe = null;
        return new Promise(async(resolve, reject) => {
          const docRef = query(collection(db, 'Usuarios'), where("UUID", "==", id)); 
          unsubscribe = await onSnapshot(docRef, (query) => {
            let dato = {};
            query.forEach(doc => {
              const d = doc.data();
              const i = {'Id': doc.id};
              dato = {...d, ...i};
            });
            resolve(dato);
          }, reject);
        });
        // return () => {
        //   if (unsubscribe) {
        //     unsubscribe();
        //   }
        // };
      };
// export const GetOrders = async(email) => {
    
//     //checkeamos que exista un registro con el email del logeado, if docy.lenght > 0
//     let docy = []
//     const docRef = query(collection(db, 'Registros'), where("email", "==", email));
//     const querySnapshot = await getDocs(docRef);
//       querySnapshot.forEach((doc) => {
//         docy.push(doc.data());
//     });
  
  
//     if(docy[0]){
//       //si existen registros los devuelve en una lista
//       return new Promise((resolve, reject) => {
//         const docRef = query(collection(db, 'Ordenes'), where("iddoc", "in", docy[0].iddoc), where("Email_cli", "==", email)); 
//         onSnapshot(docRef, (query) => {
//           let dato = [];
//           query.forEach(doc => {
//             const d = doc.data();
//             const i = {'Id': doc.id};
//             dato.push({...d, ...i});
//           });
//           resolve(dato);
//         }, reject);
//       })
//     };
//   };

// export const addregister = async(ID, Name, email) =>{
  
//   let docid = []
//   const docRef = query(collection(db, 'Registros'), where("email", "==", email));
//     const querySnapshot = await getDocs(docRef);
//       querySnapshot.forEach((doc) => {
//         docid.push(doc.id);
//     });
//   if(docid[0]){
//     const docRef = doc(db,'Registros', docid[0])
//     await updateDoc(docRef, {iddoc:arrayUnion(ID)})
//   }else{
//     const docRef = doc(collection(db, 'Registros'))
//     await setDoc(docRef, {displayName:Name, email:email, iddoc:[ID]})
//   }
  
// }


