import React from 'react';
import {useAuth} from "../context/authcontext";
//import {Success} from "./alert";
import cerrarsesion from '../images/cerrar-sesion.png'
import {Link} from 'react-router-dom'
import whatsapp from '../images/whatsapp.png'

export function Showafterloged(){

    const {user, logout} = useAuth();

    const handlelogout = async () => {
        try{
            await logout();
        } catch (error) {
            console.log(error)
        }
    };

    if(user){
        return(
    <div className='col'>
        <div className="collapse navbar-collapse justify-content-start" id="navbarNav">

                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className="nav-link hogar" to="/ordenes">Ordenes de servicio</Link>
                        </li>
                        {/* <li className="nav-item">
                            <a className="nav-link" href=''>Whatsapp</a>
                        </li> */}
                        <li className="nav-item">
                            <a className="nav-link cocina" href='https://www.peabody.com.ar/repuestos.php'>Repuestos y accesorios</a>
                        </li>
                    </ul>
                    <div className="flex-column"  style={{marginTop:"10px", lineHeight:'0.5rem'}}>
                        <a href=''>
                            <img className='mx-4 mb-2' src={whatsapp} width='50rem' height='50rem' alt=''/>
                        </a>
                    </div>  
                    <div className="flex-column"  style={{lineHeight:'1rem'}}>
                        <p>{user?user.displayName:'Unnamed'}</p>
                        {/* <p>{user?user.email:'loading...'}</p> */}
                    </div>
                    <button className="btn btn-light btn-sm mx-1"
                        onClick = {handlelogout}
                        style={{margin: '0 auto', border:'none',backgroundColor: '#ffffff'}}>
                        <img src={cerrarsesion} width="35rem" height="35rem"/>
                    </button>
        </div>
        {/* <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
            <div className="flex-column" align='left' style={{lineHeight:'0.5rem'}}>
                <a href=''>
                    <img className='mx-4 mb-2' src={whatsapp} width='50rem' height='50rem' alt=''/>
                </a>
            </div>  
            <div className="flex-column" align="left" style={{lineHeight:'0.5rem'}}>
                <p>{user?user.displayName:'Unnamed'}</p>
                <p>{user?user.email:'loading...'}</p>
            </div>
            <button className="btn btn-light btn-sm mx-1"
                        onClick = {handlelogout}
                        style={{margin: '0 auto', border:'none',backgroundColor: '#ffffff'}}>
                        <img src={cerrarsesion} width="35rem" height="35rem"/>
            </button>
        </div>     */}
    </div>
    )}
}