import React, { useState, useRef, useEffect } from 'react'
import impresora from '../images/impresora.png'
import factura from '../images/factura.png'
import camara from '../images/camara.png'
import verificacion from '../images/lista-de-verificacion.png'
import frame from '../images/frame.png'
import { useReactToPrint } from 'react-to-print';
import CryptoJS from 'crypto-js';
import { AddOrders, GetPerfil, GetCategorias, GetTalleres } from './dbfirebase'
import { useAuth } from "../context/authcontext";
import { useGeneral } from '../context/generalContext'
import { doc, collection } from "firebase/firestore";
import { db } from "../components/firebase";
import { ToastContainer, toast } from 'react-toastify';
import { storage } from "../components/firebase";
import { ref, uploadBytes } from "firebase/storage";
import Autocomplete from 'react-google-autocomplete';
import check from '../images/check-48.png'
import QRGenerator from './QRGenerator';
import garantiaImage from '../images/GARANTIA.PNG';
import facturableImage from '../images/Facturable.PNG';
import ServicesPostventaLogo from '../images/ServicesPostventaLogo.PNG'
import { Table } from './table'
import {columnsTalleres} from './columns'

export const NewOrder = () => {

    const [isChecked, setIsChecked] = useState(false);
    const [image, setImage] = useState()
    const [docimage, setDocImage] = useState()
    const [iddoc, setIdDoc] = useState()
    const [cliente, setCliente] = useState({
        Actualizado: [],
        Cliente: '',
        Domicilio_cli: '',
        CuitDni_cli: '',
        Telefono_cli: '',
        Email_cli: '',
        Categoria: '',
        Articulo: '',
        Desperfecto: '',
        Comentarios: '',
        Garantia: false,
        Taller: '',
        Tipo: '',
        Estado: 'Pendiente',
        Fecha: new Date().toLocaleString(),
        TimeStamp: new Date(),
        Repuestos: [],
        Mdo: '-',
        Firmar:'-',
        Num_Serie:'-',
        Num_Os:'-'
    })

    const [facturacion, setFacturacion] = useState({
        NumFactura: '-',
        RazonSocialCli: '-',
        FechaCompra: '-',
    })
    const [place, setPlace] = useState('')

    const OrderRef = useRef(null)
    const refrepuestos = useRef(null)
    
    const qrData = 'https://atencion.peabody.com.ar/'


    const [sended, setSended] = useState()
    const [selectedOption1, setSelectedOption1] = useState();
    const [selectedOption2, setSelectedOption2] = useState();
    const [selectedOption3, setSelectedOption3] = useState();
    const [selectedOption4, setSelectedOption4] = useState();

    const [descripcion1, setDescripcion1] = useState()
    const [descripcion2, setDescripcion2] = useState()
    const [descripcion3, setDescripcion3] = useState()
    const [descripcion4, setDescripcion4] = useState()

    const [date1, setDate1] = useState()
    const [date2, setDate2] = useState()
    const [date3, setDate3] = useState()
    const [date4, setDate4] = useState()

    const [userdatos, setUserdatos] = useState()
    const [categorias, setCategorias] = useState()
    const [talleres_list, setTalleres] = useState()

    const { userData, generateSecretKey } = useGeneral()

    const perfil = async () => {
        const dato = await userData
        setUserdatos(dato)
    }

    setTimeout(perfil, 1000)


    const handleForm1Change = (e) => {
        const { name, value } = e.target;
        setCliente((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleForm2Change = (e) => {
        const { name, value } = e.target;
        setFacturacion((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
        setCliente((prevState) => ({ ...prevState, ['Garantia']: e.target.checked }))
    }


    const handleFormSubmit = async () => {
        const docRef = doc(collection(db, 'Ordenes'))
        let secretKey = generateSecretKey('Ordenes')
        
        // const id = docRef.id
        // // Creamos un numero a partir del ID
        // let number = []
        // const charArray = id.substring(5, 10).split("");
        // charArray.map((char) => {
        //     let numb = char.charCodeAt()
        //     number.push(numb)
        // })
        // // Usamos el numero generado como clave secreta
        // const secretKey = number.join('').toString()
        setIdDoc(secretKey)

        // const Mdo = cliente.Tipo && categorias.filter((categoria) => (
        //     categoria.Categoria === cliente.Categoria)).map((producto) => (
        //         producto[`${cliente.Tipo}`]['MDO']
        //     ))

        // const Descripcion = cliente.Tipo && categorias.filter((categoria) => (
        //     categoria.Categoria === cliente.Categoria)).map((producto) => (
        //         producto[`${cliente.Tipo}`][cliente.Articulo]
        //     ))

        // const Facturacion = facturacion.NumFactura && facturacion.map((f) =>{
        //     return {
        //         NumFactura: f.NumFactura || 'none',
        //         RazonSocial: f.RazonSocial || 'none',
        //         FechaCompra: f.FechaCompra || 'none',
        //       };
        // })

        try {
            const ord = { ...cliente, ...userdatos, ...{ iddoc: secretKey }, ...facturacion }

            if (Object.keys(ord).some(key => ord[key] === "") || place == '') {
                toast.error('Debe completar todos los campos de usuario', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true
                })
            } else {
                await AddOrders(ord, docRef);
                uploadimage(docimage, `factura_de_compras/${secretKey + "_" + facturacion.FechaCompra + "_" + facturacion.RazonSocialCli + "_" + facturacion.NumFactura}.jpg`);
                uploadimage(image, `ProductoOrdenes/${secretKey + "_" + cliente.CuitDni_cli}.jpg`);
                setSended(true)
                toast.success('Datos cargados con exito', {
                    position: "top-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true
                })
            }

        } catch (error) {
            console.log(error)
        }
    };

    //input para subir foto de producto
    const getimage = (event) => {
        const imagen = event.target.files[0];
        setImage(imagen);
    };
    
    const getdocimage = (event) => {
        const docimagen = event.target.files[0];
        setDocImage(docimagen);
    };

    const uploadimage = (image, name) => {
        const storageRef = ref(storage, name);
        const metadata = {
            contentType: 'image/jpeg',
        };
        uploadBytes(storageRef, image, metadata).then((snapshot) => {

        });
    }

    //boton que dispara el input para subir la foto
    // const FileUploadButton = ({ getimage }) => {
    //     return (
    //         <button type="file" accept="image/*;capture=camera" style={{ border: 'none', backgroundColor: '#ffffff' }} onClick={getimage}>
    //             <label style={{ margin: '0 20px' }}>Adjuntar fotos</label>
    //             <img src={factura} width='50rem' height='50rem' alt='' />
    //         </button>
    //     );
    // };

    // const generateID = () =>{
    //     // ID original
    //     const nombre = 'null' || document.getElementById('nombre').value
    //     const cuitdni = 'null' || document.getElementById('cuitdni').value

    //     const id = nombre+cuitdni; //Este ID puede ser generado con la concatenacion de varios datos del cliente, como nombre y dni
    //     // Creamos un numero a partir del ID
    //     let number = []
    //     const charArray = id.substring(0, 8).split("");
    //     charArray.map((char) => {
    //         let numb = char.charCodeAt()
    //         number.push(numb)
    //     })
    // }

    // // Usamos el numero generado como clave secreta
    // const secretKey = number.join('')
    // // Generar el hash
    // const hash = CryptoJS.AES.encrypt(id, secretKey).toString();

    // // Descifrar el hash para obtener el ID original
    // const bytes = CryptoJS.AES.decrypt(hash, secretKey);
    // const decryptedId = bytes.toString(CryptoJS.enc.Utf8);

    //Funcion imprimir
    const handlePrint = useReactToPrint({
        content: () => OrderRef.current,
    },
    );
    
    
    const getPageMargins = () => {
        return `@page { margin: ${'1rem'} ${'1rem'} ${'1rem'} ${'1rem'} !important; }`;
    };

    //datos categoria
    const categoria = async () => {
        const cat = await GetCategorias()
        const categ = cat.filter(objeto => Object.keys(objeto)[0] !== "Categoria" && Object.keys(objeto)[0] !== "sku")
        // console.log(cat)
        setCategorias(categ)
    }

    const talleres = async() =>{
        const taller =await GetTalleres()
        setTalleres(taller)
    }
    
    useEffect(() => {
        categoria()
        talleres()
    }, [])

     // Obtener la fecha actual
    const today = new Date().toISOString().split('T')[0];

  // Obtener la fecha hace un año
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
    const formattedOneYearAgo = oneYearAgo.toISOString().split('T')[0];


    const listafallas = [
        {
            'No Enciende': 'No enciende a nivel visual ni funcional'
        },
        {
            'No Acciona': 'Enciende a nivel visual pero presenta un desperfecto funcional'
        },
        {
            'Operativo No Visible': 'Acciona a nivel funcional pero no enciende a nivel visual'
        },
        {
            'Rotura': 'Presenta una rotura o desgaste en el cuerpo o accesorio'
        },
        {
            'Codigo De Error': 'No acciona por causa de un código de error detallado'
        },
        {
            'Faltante': 'Presenta un faltante de accesorio o cuerpo de producto'
        },
        {
            'Derretido': 'Presenta signos de derretimiento en algún insumo o accesorio'
        },
        {
            'No conforme': 'Es funcional pero no cumple con las expectativas'
        },
        {
            'Daño Inducido': 'Presenta daños por uso inadecuado o externo'
        },
        {
            'A Normalizar': 'Inconveniente incategorizable que requiere detalles en las observaciones'
        }
    ]

    const asteriscoStyle = {
        color: 'red',
        fontSize: '1.2em', // Tamaño del asterisco
        fontWeight: 'bold', // Texto en negrita
        verticalAlign: 'super', // Alineación vertical para superíndice
      };

    return (
        
        <div style={{ marginBottom: '20px', marginTop: '20px'}} ref={OrderRef}>
            <style>{getPageMargins()}</style>
            <ToastContainer />
            <div className='container' >
                <div className='row'>
                    <div className='col-6 d-flex justify-content-start d-none d-print-block'> 
                        <img src={ServicesPostventaLogo} width="210" height="60"></img>
                    </div>
                    <div className='col-6 d-flex justify-content-end d-none d-print-block'>
                        <p className='d-flex justify-content-end'>Fecha: {new Date().toLocaleString().split(',')[0]}</p>
                    {/* Titulo y ID documento */}
                    </div>
                </div>
                <div className="row" style={{ marginBottom: '15px' }}>
                    <div className='col-6 d-flex justify-content-start'>
                        <h3>Orden de servicio</h3>
                    </div>
                    <div className='col-6 d-flex justify-content-end'>
                        <p>ID: {iddoc ? iddoc : 'Documento sin registrar'}</p>
                    </div>
                </div>
                {/* Datos taller y boton imprimir */}
                <div style={{ borderBottom: '1px solid', width: '100%', marginBottom: '20px', borderBottomColor: '#bcbcbc' }}>
                    <div className="row">
                        <p>*Aca se tomaran los datos del taller seleccionado de un desplegable con la lista y datos de talleres*</p>
                        <p>*Quizas podamos mostrar una tabla con los talleres y filtrar por ciudad*</p>
                        <p>*¿Agregar un mapa para seleccionar? Necesitaremos solicitar permiso para acceder a datos de posicionamiento*</p>
                        {talleres_list &&
                        <Table
                            showDetail={true} showComponents={true} listaOrdenes={talleres_list}
                            source={"orden"} settingcolumns={columnsTalleres}
                            rows={10} globalfilter={true}
                            statefilter={true}
                        />}
                        {userdatos && <p className='col-lg-6 d-flex mb-1'>Responsable: {userdatos.Titular}</p>}
                        {userdatos && <p className='col-lg-6 d-flex mb-1'>Razon social: {userdatos.Taller}</p>}
                        {userdatos && <p className='col-lg-6 d-flex mb-1'>Domicilio: {userdatos.Direccion}</p>}
                        {userdatos && <p className='col-lg-6 d-flex mb-1'>Telefono: {userdatos.Telefono}</p>}
                        {userdatos && <p className='col-lg-6 d-flex mb-1'>E-mail: {userdatos.Email}</p>}
                        {userdatos && <p className='col-lg-6 d-flex mb-1'>Cuit: {userdatos.Cuit}</p>}
                    </div>
                    <div className="row" style={{ marginBottom: '20px' }}>
                        <div className='col-12 d-flex justify-content-end d-print-none'>
                        {sended && 
                            <button style={{ border: 'none', backgroundColor: '#ffffff' }} onClick={() => { handlePrint() }}>
                                <label style={{ margin: '0 20px' }}>Imprimir</label>
                                <img src={impresora} width='50rem' height='50rem' alt='' />
                            </button >
                        }   
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className='col-12 d-flex justify-content-start' style={{ marginBottom: '20px' }}>
                        <h4>Datos del cliente:</h4>
                    </div>

                    <form className= 'd-flex justify-content-center' style={{ marginBottom: '20px' }}>
                        <div className="row" style={{ borderBottom: '1px solid', width: '100%', borderBottomColor: '#bcbcbc' }}>

                        <p>*Aca los datos de cliente se cargaran la primera vez en la colleccion usuarios y se llamaran para el resto de ordenes*</p>
                            <div className='col-lg-6 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Nombre<span style={asteriscoStyle}>*</span>:</label>
                                <input
                                    type="text"
                                    name="Cliente"
                                    //onChange = {handlechange} 
                                    className="form-control"
                                    placeholder="Nombre completo"
                                    id="nombre"
                                    autoFocus
                                    onChange={handleForm1Change}
                                    style={{ maxWidth: '25rem', border: cliente.Cliente.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                                    disabled={sended ? true : false}
                                />
                            </div>


                            <div className='col-lg-6 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Domicilio<span style={asteriscoStyle}>*</span>:</label>
                                <Autocomplete
                                    name='Domicilio_cli'
                                    className='form-control'
                                    style={{ maxWidth: '25rem' }}
                                    //onChange={handleForm1Change}
                                    placeholder='Calle, localidad, provincia'
                                    id='domicilio'
                                    disabled={sended ? true : false}
                                    apiKey={process.env.REACT_APP_MAP} // Reemplazar con la API key de Google Places
                                    onPlaceSelected={(place, autocomplete) => {
                                        // Obtén la dirección del lugar seleccionado y actualiza el estado 'taller'
                                        const addressComponents = place.address_components || [];
                                        let direccion = '';
                                        let localidad = '';
                                        let provincia = '';

                                        addressComponents.forEach((component) => {
                                            if (component.types.includes('street_number') || component.types.includes('route')) {
                                                direccion += component.long_name + ' ';
                                            }
                                            if (component.types.includes('locality')) {
                                                localidad = component.long_name;
                                            }
                                            if (component.types.includes('administrative_area_level_1')) {
                                                provincia = component.long_name;
                                            }
                                            // Puedes agregar más lógica aquí para otros componentes de dirección si es necesario
                                        });

                                        // Actualiza el estado 'taller'
                                        setCliente((prevCliente) => ({
                                            ...prevCliente,
                                            Domicilio_cli: direccion.trim(),
                                            Localidad_cli: localidad,
                                            Provincia_cli: provincia,
                                        }));
                                        setPlace(place)
                                    }}
                                    options={{
                                        types: ['address'],
                                        componentRestrictions: { country: 'ar' },
                                        // fields: ['postal_code']
                                    }}
                                />
                            </div>

                            
                            <div className='col-lg-6 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Cuit/Dni<span style={asteriscoStyle}>*</span>:</label>
                                <input
                                    type="text"
                                    name="CuitDni_cli"
                                    //onChange = {handlechange} 
                                    className="form-control"
                                    placeholder="Sin guiones ni puntos"
                                    id="cuitdni"
                                    onChange={handleForm1Change}
                                    style={{ maxWidth: '25rem' }}
                                    disabled={sended ? true : false}
                                    maxLength="15"
                                />
                            </div>
                            
                            <div className='col-lg-6 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Telefono<span style={asteriscoStyle}>*</span>:</label>
                                <input
                                    type="text"
                                    name="Telefono_cli"
                                    //onChange = {handlechange} 
                                    className="form-control"
                                    placeholder="Sin guiones ni puntos"
                                    id="telefono"
                                    onChange={handleForm1Change}
                                    style={{ maxWidth: '25rem' }}
                                    disabled={sended ? true : false}
                                    maxLength="15"
                                />
                            </div>

                            
                            <div className='col-lg-6 d-flex mb-3 contenedor' style={{ alignItems: 'center' }}>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>E-mail<span style={asteriscoStyle}>*</span>:</label>
                                <input
                                    type="email"
                                    name="Email_cli"
                                    //onChange = {handlechange} 
                                    className="form-control"
                                    placeholder="ejemplo@ejemplo.com.ar"
                                    id="email"
                                    onChange={handleForm1Change}
                                    style={{ maxWidth: '25rem' }}
                                    disabled={sended ? true : false}
                                />
                            </div>

                            <div className='col-lg-6 d-flex mb-3 contenedor' style={{alignItems: 'center' }}>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Localidad:</label>
                                <input 
                                    type = "text"
                                    name = "Localidad_cli"
                                    className = "form-control"
                                    placeholder = "Localidad"
                                    id = "Localidad"
                                    onChange={handleForm1Change}
                                    style={{ maxWidth: '25rem' }}
                                    defaultValue={place && place.address_components && place.address_components[2] && place.address_components[2].long_name ? place.address_components[2].long_name : ''}
                                    disabled = {true}
                                />  
                            </div>

                            <div className='col-lg-6 d-flex mb-3 contenedor' style={{alignItems: 'center' }}>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Provincia:</label>
                                <input 
                                    type = "text"
                                    name = "Provincia_cli"
                                    className = "form-control"
                                    placeholder = "Provincia"
                                    id = "Provincia"
                                    onChange={handleForm1Change}
                                    style={{ maxWidth: '25rem' }}
                                    defaultValue={place && place.address_components && place.address_components[4] && place.address_components[4].long_name ? place.address_components[4].long_name : ''}
                                    disabled = {true}
                                />  
                            </div>

                            <div className='col-lg-6 d-flex mb-3 contenedor' style={{ alignItems: 'center' }}>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Codigo Postal<span style={asteriscoStyle}>*</span>:</label>
                                <input
                                    type="text"
                                    name="CodigoPostal_cli"
                                    //onChange = {handlechange} 
                                    className="form-control"
                                    placeholder="Codigo postal"
                                    id="codigopostal"
                                    onChange={handleForm1Change}
                                    style={{ maxWidth: '25rem' }}
                                    disabled={sended ? true : false}
                                />
                            </div>

                        </div>
                    </form>
                </div>
               
                
               
                <div className="row">                                                                    
                    <div className='col-6 d-flex justify-content-start'>
                        <h4>Datos del producto:</h4>
                    </div>
                    <div className='col-6 d-flex justify-content-end'>
                    {isChecked? <img className="imagen" src={garantiaImage} alt="garantia"/>:
                                    <img className="imagen" src={facturableImage} alt="facturable"/>}
                    </div>
                    
                    <div className='col-lg-6 d-flex ms-1 mb-4 form-switch' style={{ alignItems: 'center' }}>
                        <label style={{ margin: '0 1rem' }}>¿En garantía?: </label>
                        <input className="form-check-input"
                            type="checkbox"
                            value=""
                            name="Garantia"
                            id="flexCheckDefault"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                            disabled={sended ? true : false}
                            style={{margin:'initial'}} />
                            
                    </div>

                    {isChecked && <div className="row">
                        <div className='col-lg-3 d-flex justify-content-start ms-auto mb-3 flex-column align-items-center'>
                            <label style={{ margin: '0 1rem', maxWidth: '10rem', minWidth: '1rem' }}>N° factura<span style={asteriscoStyle}>*</span>:</label>
                            <input type="text"
                                name="NumFactura"
                                className="form-control"
                                placeholder="Nº Factura"
                                id="NumFactura"
                                onChange={handleForm2Change}
                                style={{ minWidth: '8rem' }}
                                disabled={sended ? true : false}
                            />
                        </div>
                        <div className='col-lg-3 d-flex justify-content-start ms-auto mb-3 flex-column align-items-center'>
                            <label style={{ margin: '0 1rem', maxWidth: '10rem', minWidth: '1rem' }}>Razon Social<span style={asteriscoStyle}>*</span>:</label>
                            <input type="text"
                                name="RazonSocialCli"
                                className="form-control"
                                placeholder="Razon Social"
                                id="RazonSocialCli"
                                onChange={handleForm2Change}
                                style={{ minWidth: '8rem' }}
                                disabled={sended ? true : false}
                            />
                        </div>
                        <div className='col-lg-3 d-flex justify-content-start ms-auto mb-3 flex-column align-items-center'>
                            <label style={{ margin: '0 1rem', maxWidth: '10rem', minWidth: '1rem' }}>Fecha<span style={asteriscoStyle}>*</span>:</label>
                            <input type="date"
                                name="FechaCompra"
                                className="form-control"
                                placeholder="Fecha de compra"
                                id="FechaCompra"
                                onChange={handleForm2Change}
                                style={{ minWidth: '8rem' }}
                                disabled={sended ? true : false}
                                max={today}
                                min={formattedOneYearAgo}
                                max={today}
                                min={formattedOneYearAgo}
                            />
                        </div>
                        
                        <div className='col-sm-3 d-flex justify-content-end image-upload d-print-none' style={{ marginBottom: '20px' }}>
                            <label htmlFor='photodoc'>Adjuntar factura de compra (*jpg)
                                <img src={docimage?check:factura} width='55rem' height='55rem' alt='' style={{ margin: '0 0 0 20px' }}  />
                            </label>
                            
                            <input  type="file" accept="image/jpg" capture id='photodoc' onChange={getdocimage}></input>
                        </div>
                    </div>
                    }

                    
                    <form className= 'd-flex justify-content-center'style={{ marginBottom: '20px' }}>
                        <div className="row" style={{ borderBottom: '1px solid', width: '100%', borderBottomColor: '#bcbcbc' }}>

                            <p>*Modificar las llamadas de las funciones porque traen los datos de la lista de precios*</p>

                            <div className='col-lg-3 d-flex ms-auto mb-3 flex-column align-items-center'>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '1rem' }}>Categoria<span style={asteriscoStyle}>*</span>:</label>
                                <select
                                    name="Categoria"
                                    className="form-select"
                                    id="categoria"
                                    autoComplete="off"
                                    onChange={handleForm1Change}
                                    style={{ minWidth: '8rem' }}
                                    disabled={cliente.Tipo}
                                >
                                    <option value="">Selecciona una categoría</option>
                                    {categorias &&
                                        categorias.map((categoria) => (
                                            <option key={categoria.Grupo} value={categoria.Grupo}>
                                                {categoria.Grupo}
                                            </option>
                                        ))}
                                </select>
                                
                            </div>
                            <div className='col-lg-3 d-flex ms-auto mb-3 flex-column align-items-center'>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '1rem' }}>Tipo<span style={asteriscoStyle}>*</span>:</label>
                                <select
                                    name="Tipo"
                                    className="form-select"
                                    id="tipo"
                                    autoComplete="off"
                                    onChange={handleForm1Change}
                                    style={{ minWidth: '8rem' }}
                                    disabled={!cliente.Categoria || cliente.Articulo}
                                >
                                    <option value="">Selecciona un tipo</option>
                                    {cliente.Categoria &&
                                        categorias
                                            .filter((categoria) => categoria.Grupo === cliente.Categoria)
                                            .map((producto) => {
                                                const nuevoProducto = {};
                                                for (const key in producto) {
                                                    if (key !== "Grupo") {
                                                        nuevoProducto[key] = producto[key];
                                                    }
                                                }
                                                return Object.keys(nuevoProducto).map((art) => (
                                                    <option key={art} value={art}>
                                                        {art}
                                                    </option>
                                                ));
                                            })}
                                </select>
                            </div>

                            <div className='col-lg-3 d-flex ms-auto mb-3 flex-column align-items-center'>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '1rem' }}>Articulo<span style={asteriscoStyle}>*</span>:</label>
                                <input type="text"
                                    name="Articulo"
                                    //onChange = {handlechange} 
                                    className="form-control"
                                    placeholder="Articulo"
                                    id="articulo"
                                    list="Articulo"
                                    autoComplete="off"
                                    onChange={handleForm1Change}
                                    style={{ minWidth: '8rem' }}
                                    disabled={!cliente.Tipo || sended}
                                />
                                <datalist id="Articulo">
                                    {cliente.Tipo && categorias.filter((categoria) => (
                                        categoria.Grupo === cliente.Categoria)).map((producto) => (
                                            Object.keys(producto[`${cliente.Tipo}`][0]).map((codigo) => (
                                                <option key={codigo} value={codigo} />
                                            ))))}
                                </datalist>
                            </div>
                            
                            <div className='col-lg-3 d-flex ms-auto mb-3 flex-column align-items-center'>
                                <label style={{ margin: '0 1rem', maxWidth: '15rem', minWidth: '1rem' }}>Descripcion<span style={asteriscoStyle}>*</span>:</label>
                                <input
                                    type="text"
                                    name="Descripcion"
                                    //onChange = {handlechange} 
                                    className="form-control"
                                    placeholder="Descripcion"
                                    id="descripcion"

                                    onChange={handleForm1Change}
                                    style={{ minWidth: '8rem' }}
                                    disabled={true}
                                    value={cliente.Articulo && categorias.filter((categoria) => (
                                        categoria.Grupo === cliente.Categoria)).map((producto) => (
                                            producto[`${cliente.Tipo}`][0][`${cliente.Articulo}`]
                                        ))}
                                />
                            </div>
                            <div className= 'row'>
                                <div className='col-lg-6 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
                                    <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Numero de serie<span style={asteriscoStyle}>*</span>:</label>
                                    <input
                                        type="text"
                                        name="Num_Serie"
                                        //onChange = {handlechange} 
                                        className="form-control"
                                        placeholder="Numero de serie"
                                        id="num_serie"
                                        //autoComplete="off"
                                        disabled={!cliente.Articulo || sended}
                                        onChange={handleForm1Change}
                                    // disabled={sended? true:false}
                                    />
                                </div>
                                <div className='col-lg-6 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
                                    <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Orden de servicio<span style={asteriscoStyle}>*</span>:</label>
                                    <input
                                        type="text"
                                        name="Num_Os"
                                        //onChange = {handlechange} 
                                        className="form-control"
                                        placeholder="Numero de orden"
                                        id="numero_os"
                                        //autoComplete="off"
                                        disabled={!cliente.Articulo || sended}
                                        onChange={handleForm1Change}
                                    // disabled={sended? true:false}
                                    />
                                </div>
                            </div>
                            {/* <div className='col-lg-12 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Falla:</label>
                                <input
                                    type="text"
                                    name="Desperfecto"
                                    //onChange = {handlechange} 
                                    className="form-control"
                                    placeholder="Descripcion de la falla del producto"
                                    id="falla"
                                    list='Falla'
                                    //autoComplete="off"
                                    disabled={!cliente.Articulo || sended}
                                    onChange={handleForm1Change}
                                // disabled={sended? true:false}
                                />
                                <datalist id="Falla">
                                    {listafallas.map((fallas) => (
                                        Object.keys(fallas).map((key) =>
                                            <option key={key} value={key}>
                                                {fallas[key]}
                                            </option>)
                                    ))}
                                </datalist>
                            </div> */}
                            <div className='col-lg-12 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Mtv. devolucion<span style={asteriscoStyle}>*</span>:</label>
                                <select
                                    name="Desperfecto"
                                    className="form-control"
                                    placeholder="Motivo de la devolucion"
                                    id="falla"
                                    //value={valorSeleccionado} // Aquí debes definir el valor seleccionado
                                    onChange={handleForm1Change}
                                    disabled={!cliente.Articulo || sended}
                                >
                                    <option value="">Selecciona una falla</option> {/* Opción vacía para deseleccionar */}
                                    {listafallas.map((fallas) => (
                                    Object.keys(fallas).map((key) => (
                                        <option key={key} value={key}>
                                        {fallas[key]}
                                        </option>
                                    ))
                                    ))}
                                </select>
                            </div>

                            <div className='col-lg-12 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Falla declarada<span style={asteriscoStyle}>*</span>:</label>
                                <input
                                    type="text"
                                    name="Desperfecto_declarado"
                                    className="form-control"
                                    placeholder="Descripcion de la falla del producto"
                                    id="falla"
                                    //value={valorSeleccionado} // Aquí debes definir el valor seleccionado
                                    onChange={handleForm1Change}
                                    disabled={!cliente.Articulo || sended}
                                    onChange={handleForm1Change}
                                />
                            </div>
                            
                            <div className='col-lg-12 d-flex mb-3 contenedor' style={{ alignItems: 'center' }}>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Observacion<span style={asteriscoStyle}>*</span>:</label>
                                <input
                                    type="text"
                                    name="Comentarios"
                                    //onChange = {handlechange} 
                                    className="form-control"
                                    placeholder="Estado general del producto. ej. Marcas, Sin accesorios, en caja original...etc."
                                    id="observaciones"
                                    autoComplete="off"
                                    disabled={!cliente.Articulo || sended}
                                    onChange={handleForm1Change}
                                // disabled={sended? true:false}
                                />
                            </div>
                        </div>
                    </form>
                    <div className="row">
                        <div className='col-12 d-flex justify-content-start d-print-none'>
                            {/* <h4>Datos del servicio:</h4> */}
                        </div>
                        <div className='col-12 d-flex justify-content-end image-upload d-print-none' style={{ marginBottom: '20px' }}>
                            
                            <label  className = 'd-print-none' htmlFor='photo'>Adjuntar fotos (*jpg):
                                <img src={image ? check:camara} width='50rem' height='50rem' alt='' style={{ margin: '0 0 0 20px' }} />
                            </label>
                            
                            <input type="file" accept="image/jpg" id='photo' onChange={getimage} capture='environment'></input>
                        </div>
                        <div className='card-body col-12 d-flex justify-content-start d-print-none' style={{ overflowX: "auto", marginBottom: '20px' }}>
                            
                        </div>
                        <p>*La orden se guardara igual que las generadas por el taller, agregar un campo de validacion para cuando el taller reciba el producto, similar a "firmar retiro"*</p>
                        <div className='col-12 d-flex justify-content-center d-print-none' style={{ marginBottom: '20px' }}>
                            <button type="submit" onClick={handleFormSubmit} className='col-12 btn btn-outline-success' disabled={sended ? true : false} >Guardar</button>
                        </div>
                        {/* <div className='col-12 d-flex justify-content-end image-upload d-print-none'style={{marginBottom:'20px'}}>
                            <label htmlFor='photo'>Adjuntar conformidad:    
                                <img src={verificacion} width='50rem' height='50rem' alt='' style={{margin:'0 0 0 20px'}}/>
                            </label>
                            <input type="file" accept="image/*" capture id='photo' onChange={getimage}></input>
                    </div> */}
                        <div className='row'>
                            <div className='col-6 d-none d-print-block' style={{ marginTop: '150px' }}>
                                <label className='d-flex justify-content-center'>......................................................................</label>
                                <label className='d-flex justify-content-center'>Firma</label>
                            </div>
                            {/* QR para acceder a la app del cliente */}
                            <div className='col-6 d-none d-print-block' style={{marginTop:'20px'}}>
                                <p className='d-flex justify-content-end' style={{margin:'0 0 0 0'}}>¡Hace el seguimiento desde la web!</p>
                                <div className='d-flex justify-content-end' style={{margin:'0 4.5rem 0 0'}} >
                                    <QRGenerator data={qrData}/>
                                </div>
                                <p className='d-flex justify-content-end'style={{margin:'0 0 0 0'}}>https://atencion.peabody.com.ar/</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}