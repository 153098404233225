import React, {useState, useRef, useEffect} from 'react'
import { useReactToPrint } from 'react-to-print';
import impresora from '../images/impresora.png'
import factura from '../images/factura.png'
import ServicesPostventaLogo from '../images/ServicesPostventaLogo.PNG'

export const OrdenModal = (documentInfo) =>{


    const datos = {
        Fecha: documentInfo.data.Fecha,
        ID: documentInfo.data.iddoc,
        Titular: documentInfo.data.Titular,
        Taller: documentInfo.data.Taller,
        Direccion: documentInfo.data.Direccion,
        Telefono: documentInfo.data.Telefono,
        Email: documentInfo.data.Email,
        Cliente: documentInfo.data.Cliente,
        Domicilio_cli: documentInfo.data.Domicilio_cli,
        CuitDni_cli: documentInfo.data.CuitDni_cli,
        Telefono_cli: documentInfo.data.Telefono_cli,
        Email_cli: documentInfo.data.Email_cli,
        Categoria: documentInfo.data.Categoria,
        Articulo: documentInfo.data.Articulo,
        Descripcion: documentInfo.data.Descripcion,
        Desperfecto: documentInfo.data.Desperfecto,
        Comentarios: documentInfo.data.Comentarios,
        Garantia: documentInfo.data.Garantia
    }

    const OrderRef = useRef(null)

    const handlePrint = useReactToPrint({
        content: () => OrderRef.current,
        },
    );

    const closeModal = () => {
        const modal = document.getElementById('Modal');
        const close = document.getElementById('closemodal')
        const backdrop = document.querySelector('.modal-backdrop');
        
        if(OrderRef.current.classList.contains('show')){           
            modal.classList.remove('show');
            modal.style.display = 'none'
            document.body.classList.remove('modal-open');
            modal.classList.add('show');
        }
        if (backdrop) {
            backdrop.remove();
        }
        document.body.style.overflow = 'auto';
    };

    const handleCloseModal = () => {
        closeModal();
    };

    window.history.pushState({}, '', '');

    useEffect(() => {

        const handlePopstate = () => {
            if (OrderRef.current.classList.contains('show')) {
                closeModal()
            }
        };
      
        window.addEventListener('popstate', handlePopstate);
      
        return () => {
          
            window.removeEventListener('popstate', handlePopstate);
        };
        
    },[]);

    return(
        <div className="modal"
                id="Modal"
                tabIndex={-1}
                aria-labelledby="ModalLabel"
                aria-hidden="true"
                style={{marginBottom:'20px' ,marginTop:'20px'}} ref={OrderRef}>
        <div className="modal-dialog modal-fullscreen-lg-down modal-lg ">

        <div className='container modal-content' style={{ overflowX: "auto" }} >
            <div className='row' style={{marginTop:'15px'}}>
                    <div className='col-6 d-flex justify-content-start'>
                        <img src={ServicesPostventaLogo} width="210" height="60"></img>
                    </div>
                    <div className='col-6 d-flex justify-content-end'>
                        <button type="button" className='btn-close' onClick={()=>handleCloseModal()}></button>
                    </div>
            </div>
            {/* Titulo y ID documento */}
            <div className="row" style={{marginBottom:'15px'}}>
                <div className='col-6 d-flex justify-content-start'>
                    <h2>Orden de servicio</h2>
                </div>
                <div className='col-6 d-flex justify-content-end'>
                    <p>ID: {datos.ID}</p>
                </div>
            </div>
            {/* Datos taller y boton imprimir */}
            <div style={{borderBottom: '1px solid', width:'100%',marginBottom:'20px', borderBottomColor:'#bcbcbc'}}>
                <div className="row">
                    <p className='col-lg-6 d-flex mb-1'>Responsable: {datos.Titular}</p>
                    <p className='col-lg-6 d-flex mb-1'>Razon social: {datos.Taller}</p>
                    <p className='col-lg-6 d-flex mb-1'>Domicilio: {datos.Direccion}</p>
                    <p className='col-lg-6 d-flex mb-1'>Telefono: {datos.Telefono}</p>
                    <p className='col-lg-6 d-flex mb-1'>E-mail: {datos.Email}</p>
                </div>
                <div className="row" style={{marginBottom:'20px'}}>
                    <div className='col-12 d-flex justify-content-end d-print-none'>
                        <button style={{border:'none',backgroundColor: '#ffffff'}} onClick={()=>{handlePrint()}}>
                            <label style={{margin:'0 20px'}}>Imprimir</label>
                            <img src={impresora} width='50rem' height='50rem' alt=''/>
                        </button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className='col-12 d-flex justify-content-start' style={{marginBottom:'20px'}}>
                    <h4>Datos del cliente:</h4>
                </div>
            
                <form style={{marginBottom:'20px'}}>
                    <div className="row" style={{borderBottom: '1px solid', width:'100%',borderBottomColor:'#bcbcbc'}}> 
                        
                            <div className='col-lg-6 d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                                <label style={{margin:'0 1rem',minWidth:'5rem'}}>Nombre: {datos.Cliente}</label>
                            </div>
                            <div className='col-lg-6 d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                                <label style={{margin:'0 1rem',minWidth:'5rem'}}>Domicilio: {datos.Domicilio_cli}</label>
                            </div>
                    
                            <div className='col-lg-6 d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                                <label style={{margin:'0 1rem',minWidth:'5rem'}}>Cuit/Dni: {datos.CuitDni_cli}</label>
                            </div>
                            <div className='col-lg-6 d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                                <label style={{margin:'0 1rem',minWidth:'5rem'}}>Telefono: {datos.Telefono_cli}</label>
                            </div>
                                          
                            <div className='col-lg-6 d-flex mb-3' style={{alignItems: 'center' }}>
                                <label style={{margin:'0 1rem', minWidth:'5rem'}}>E-mail: {datos.Email_cli}</label>
                            </div>
                    </div>           
                </form>
            </div>
            <div className="row">
                <div className='col-12 d-flex justify-content-start'>
                    <h4>Datos del producto:</h4>
                </div>
                <div className='col-lg-6 d-flex ms-1 mb-4' style={{alignItems: 'center' }}>
                    <label style={{margin:'0 0.2rem'}}>En garantía: {datos.Garantia?'SI':'NO'}</label>
                </div>
                
            {/* {isChecked && <div className='col-12 d-flex justify-content-end image-upload d-print-none' style={{marginBottom:'20px'}}>
                                <label htmlFor='photo'>Adjuntar factura de compra
                                    <img src={factura} width='55rem' height='55rem' alt=''/>
                                </label>
                                <input type="file" accept="image/*" capture id='photo' ></input>
                        </div>} */}
                    
                <form style={{marginBottom:'20px'}}>
                    <div className="row" style={{borderBottom: '1px solid', width:'100%',borderBottomColor:'#bcbcbc'}}> 
                            
                            <div className='col-lg-6 d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                                <label className="fw-bold" style={{margin:'0 1rem',minWidth:'5rem'}}>Categoria:</label>
                                <label style={{minWidth:'5rem'}}>{datos.Categoria}</label>
                            </div>
                            <div className='col-lg-6 d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                                <label className="fw-bold" style={{margin:'0 1rem',minWidth:'5rem'}}>Articulo:</label>
                                <label style={{minWidth:'5rem'}}>{datos.Articulo}</label>
                            </div>
                
                            <div className='col-lg-12 d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                                <label className="fw-bold" style={{margin:'0 1rem',minWidth:'5rem'}}>Descripcion:</label>
                                <label style={{minWidth:'5rem'}}>{datos.Descripcion}</label>
                            </div>
                            <div className='col-lg-12 d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                                <label className="fw-bold" style={{margin:'0 1rem',minWidth:'5rem'}}>Numero de serie:</label>
                                <label style={{minWidth:'5rem'}}>{datos.Num_Serie}</label>
                            </div>
                            <div className='col-lg-12 d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                                <label className="fw-bold" style={{margin:'0 1rem',minWidth:'5rem'}}>Numero de orden:</label>
                                <label style={{minWidth:'5rem'}}>{datos.Num_Os}</label>
                            </div>
                            <div className='col-lg-12 d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                                <label className="fw-bold" style={{margin:'0 1rem',minWidth:'5rem'}}>Falla:</label>
                                <label style={{minWidth:'5rem'}}>{datos.Desperfecto}</label>
                            </div>
                                        
                            <div className='col-lg-12 d-flex mb-3' style={{alignItems: 'center' }}>
                                <label className="fw-bold" style={{margin:'0 1rem', minWidth:'5rem'}}>Observacion:</label>
                                <label style={{minWidth:'5rem'}}>{datos.Comentarios}</label>
                            </div>
                    </div>
                    <div className="row" style={{borderBottom: '1px solid', width:'100%',borderBottomColor:'#bcbcbc'}}> 
                            <h4>Repuestos:</h4>
                            <label style={{margin:'0 1rem',minWidth:'5rem', marginBottom:'20px'}}>Repuestos solicitados:</label>
                            <div className='col-12 d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <ul>
                                {datos.Repuestos && Array.isArray(datos.Repuestos) ? (datos.Repuestos.map((repuesto, index) => (
                                <li key={index}>{repuesto.Descripcion}</li>
                                ))
                                ) : (
                                    <li>No hay repuestos solicitados</li>
                                    )}
                            </ul>
                            </div>
                    </div>              
                </form>
                {/* <div className="row"> */}
                    <div className='col-12 d-flex justify-content-start' style={{marginBottom:'20px', alignItems: 'center'}}>
                        <h4>Estado de la orden: </h4>
                        <label style={{margin:'0 1rem'}}>{documentInfo.data.Estado}</label> 
                     {/*<div className='col-12 d-flex justify-content-end image-upload d-print-none'style={{marginBottom:'20px'}}>
                            <label htmlFor='photo'>Adjuntar fotos:    
                                <img src={camara} width='50rem' height='50rem' alt='' style={{margin:'0 0 0 20px'}}/>
                            </label>
                            <input type="file" accept="image/*" capture id='photo' onChange={getimage}></input>*/}
                    </div>
                    {/* <div className='card-body col-12 d-flex justify-content-start d-print-none' style={{ overflowX: "auto",marginBottom:'20px' }}>
                       
                        <table className="table table-hover table-responsive-sm table-bordered" ref={refrepuestos}>
                            <thead className = 'table-dark'>
                                <tr>
                                    <th>Fecha</th>
                                    <th>Repuesto</th>
                                    <th>Descripcion</th>
                                    <th>Cantidad</th>
                                    <th>Falla</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{width: '5rem'}}>{date1}</td>
                                    <td style={{width: '15rem'}}>
                                        <select value={selectedOption1} onChange={handleTypeSelect1} style={{ border: 'none', width: '10rem'}}>
                                            <option value="">-- Seleccionar --</option>
                                            {options.map((option) => (
                                            <option key={option.value} value={option.value} id='first-column1'>
                                                {option.value}
                                            </option>
                                            ))}
                                        </select>
                                    </td>
                                    <td style={{width: '20rem'}}>{descripcion1}</td>
                                    <td style={{width: '15rem'}}><input type="text" name="campo1" style={{ border: 'none', width: '4rem'}}/></td>
                                    <td style={{width: '15rem'}}><input type="text" name="campo1" style={{ border: 'none', width: '15rem' }}/></td>
                                </tr>
                                <tr>
                                    <td style={{width: '5rem'}}>{date2}</td>
                                    <td style={{width: '15rem'}}>
                                        <select value={selectedOption2} onChange={handleTypeSelect2} style={{ border: 'none', width: '10rem'}}>
                                            <option value="">-- Seleccionar --</option>
                                            {options.map((option) => (
                                            <option key={option.value} value={option.value} id='first-column2'>
                                                {option.value}
                                            </option>
                                            ))}
                                        </select>
                                    </td>
                                    <td style={{width: '20rem'}}>{descripcion2}</td>
                                    <td style={{width: '15rem'}}><input type="text" name="campo1" style={{ border: 'none', width: '4rem'}}/></td>
                                    <td style={{width: '15rem'}}><input type="text" name="campo1" style={{ border: 'none', width: '15rem'}}/></td>
                                </tr>
                                <tr>
                                    <td style={{width: '5rem'}}>{date3}</td>
                                    <td style={{width: '15rem'}}>
                                        <select value={selectedOption3} onChange={handleTypeSelect3} style={{ border: 'none', width: '10rem'}}>
                                            <option value="">-- Seleccionar --</option>
                                            {options.map((option) => (
                                            <option key={option.value} value={option.value} id='first-column3'>
                                                {option.value}
                                            </option>
                                            ))}
                                        </select>
                                    </td>
                                    <td style={{width: '20rem'}}>{descripcion3}</td>
                                    <td style={{width: '15rem'}}><input type="text" name="campo1" style={{ border: 'none', width: '4rem'}}/></td>
                                    <td style={{width: '15rem'}}><input type="text" name="campo1" style={{ border: 'none', width: '15rem' }}/></td>
                                </tr>
                                <tr>
                                    <td style={{width: '5rem'}}>{date4}</td>
                                    <td style={{width: '15rem'}}>
                                        <select value={selectedOption4} onChange={handleTypeSelect4} style={{ border: 'none', width: '10rem'}}>
                                            <option value="">-- Seleccionar --</option>
                                            {options.map((option) => (
                                            <option key={option.value} value={option.value} id='first-column3'>
                                                {option.value}
                                            </option>
                                            ))}
                                        </select>
                                    </td>
                                    <td style={{width: '20rem'}}>{descripcion4}</td>
                                    <td style={{width: '15rem'}}><input type="text" name="campo1" style={{ border: 'none', width: '4rem'}}/></td>
                                    <td style={{width: '15rem'}}><input type="text" name="campo1" style={{ border: 'none', width: '15rem' }}/></td>
                                </tr>
                            </tbody>
                        </table>
                    </div> */}
                    
                    {/* <div className='col-12 d-flex justify-content-center d-print-none' style={{marginBottom:'20px'}}>
                        <button type="submit" onClick={handleFormSubmit} className='col-12' >Guardar</button>    
                    </div>
                    <div className='col-12 d-flex justify-content-end image-upload d-print-none'style={{marginBottom:'20px'}}>
                            <label htmlFor='photo'>Adjuntar conformidad:    
                                <img src={verificacion} width='50rem' height='50rem' alt='' style={{margin:'0 0 0 20px'}}/>
                            </label>
                            <input type="file" accept="image/*" capture id='photo' onChange={getimage}></input>
                    </div>
                    <div className='col-6 d-none d-print-block' style={{marginTop:'100px'}}>
                        <label className='d-flex justify-content-center'>......................................................................</label>
                        <label className='d-flex justify-content-center'>Firma</label>
                    </div> 
                </div> */}
            </div>
        </div>
    </div>
    </div>
    )
}