import React, {useState, useEffect} from "react";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'

import {ProtectedRoute} from './components/protectedroutes'
import {Navbar} from './components/navbar'
import {Login} from './components/login'
import {Micuenta} from './components/micuenta'
import {Ordenes} from './components/ordenes'
import {AuthProvider} from './context/authcontext'
import {Terminos} from './components/terminos'
import {NewOrder} from './components/neworders'
import { GeneralProvider } from './context/generalContext'

function App() {
  const [logged, setLogged] = useState()
  return (
    <div className="App">
      <AuthProvider setLogged={setLogged}>
        <GeneralProvider logged = {logged}>
        <Router>
          <Navbar/>
            <div className="container p-4" style={{ minHeight: 'calc(100vh - 149px)', maxWidth: "90%"}}>
              <Routes>
                <Route path = "/" element = {<Login/>}/>
                {/* <Route path = "/micuenta" element = {<Micuenta/>}/> */}

                <Route path = "/ordenes" element = {
                  <ProtectedRoute>
                    <Ordenes/>
                  </ProtectedRoute>}/>
                  <Route path = "/nuevaOrdenes" element = {
                  <ProtectedRoute>
                    <NewOrder/>
                  </ProtectedRoute>}/>
                <Route path = "/terminosycondiciones" element = {<Terminos/>}/>
              </Routes>

            </div>
            <footer className="bottom-0 w-100 heightFooter text-center p-3" style={{backgroundColor:"#1974AA", borderTop: "5px solid #ddd"}}>
                                <small style={{color: 'white'}}>&copy; Goldmund&reg; | Todos los Derechos reservados.</small>
                            </footer>
        </Router>
        </GeneralProvider>
      </AuthProvider>
    </div>
  );
}

export default App;