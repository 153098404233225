import React, {useState, useEffect} from 'react'
import nuevodocumento from '../images/nuevo-documento.png'
import {Table} from './table'
import {columnsOrdenes} from './columns';
import {addregister} from './dbfirebase'
import CryptoJS from 'crypto-js';
import {useAuth} from "../context/authcontext";
import camara from '../images/camara.png'
import {TarjetaAcordeon} from './tarjetas'
import {ResponsiveTable} from './ResponsiveTable'
import {db} from "../components/firebase";
import {doc, getDoc, setDoc, addDoc, collection, 
    onSnapshot, query, deleteDoc, updateDoc, 
    arrayUnion, FieldValue, serverTimestamp, 
    getDocs, orderBy, startAt, endAt, where, FieldPath, limit} from "firebase/firestore";
    

export const Ordenes = () =>{

    const [dato, setDatos] = useState()
    const [formData, setFormData] = useState({IDdoc: ''});
    const {user} = useAuth();
    
    useEffect(()=>{
        //checkeamos que exista un registro con el email del logeado, if docy.lenght > 0
        let unsubscribe = null
        const fetchData = async () => {
                
            try {
                const docRef = query(collection(db, 'Ordenes'), where("Email_cli", "==", user.email));  //where("iddoc", "in", docy[0].iddoc)
                unsubscribe = await onSnapshot(docRef, (query) => {
                    let dato = [];
                    query.forEach(doc => {
                        const d = doc.data();
                        const i = {'Id': doc.id};
                        dato.push({...d, ...i});
                    });
                    
                    setDatos(dato);
                    //setActualizar(false)
                })
            }catch(error){
                console.error(error)
            }
        }
        //}
        fetchData()
        return () => {
            if (unsubscribe){
                unsubscribe()
            }
        }
    },[])


    return(
        <div>
            <div className='row'>
                <h3 className = 'col-md-2 p-0'>Bienvenido {user?user.displayName:'Unnamed'}</h3>
                {/* <a className = 'col-10 d-flex justify-content-end' href="/nuevaOrdenes">
                    Solicitar asistencia tecnica
                </a> */}
            </div>
           
            {/* tabla con datos */}
            <div style={{marginTop: '50px'}}>
                <div className="table-container">
                        {dato && 
                            <Table showDetail={true} showComponents={true} showDelete={true} listaOrdenes={dato} source={"orden"} settingcolumns={columnsOrdenes}/>
                        }
                </div>   
                <div className="acordeon-container">
                    {dato &&
                        <ResponsiveTable data={dato}/>
                    }
                </div>
            </div>
        </div>
    )
}