import React from 'react'

export const Terminos = () =>{

    return(
        <div>
            <h2>
                TÉRMINOS Y CONDICIONES
            </h2>
            <br/>
            <h5>
                CONSIDERACIONES GENERALES
            </h5>
            <p>GOLDMUND S.A, con domicilio en El Salvador 5763, Ciudad Autonoma de Buenos Aires, Argentina (en adelante “Peabody”), pone a disposición esta pagina web (en adelante, “página web” o “sitio web” indistintamente) con la finalidad de continuar brindando un servicio de postventa de calidad.
            </p>
            
            <p>El sitio web es una plataforma puesta a disposición de los consumidores y/o usuarios de productos marca “Peabody” (fabricados y/o importados exclusivamente por Goldmund S.A) para que estos puedan observar el estado de reparación de sus productos.
            </p>
           
            <p>Al acceder a la pagina web usted es responsable de respetar los presentes términos y condiciones (en adelante, “términos”). Si usted no esta de acuerdo, por favor no continue utilizando el sitio. 
            </p>
            
            <p>Se deja expresamente establecido que el uso de la pagina web confirma que ha leído y acepta los términos.
            </p>
            
            <p>Todos los pedidos y/o solicitudes que involucren el reemplazo y/o sustitución de productos deberán ser comunicados directamente a nuestra central de atención, a través del contacto disponible al efecto que se detalla al final.
            </p>
            <br/>

            <h5>
                INFORMACION DEL USUARIO
            </h5>
            <p>Al utilizar el sitio web, usted acepta no utilizarlo para ningún propósito ilegal o prohibido por estos términos y condiciones. Además, se compromete a no utilizar el sitio web para enviar, publicar o transmitir cualquier contenido que sea ilegal, dañino, amenazador, abusivo, difamatorio, vulgar, obsceno, calumnioso, invasivo de la privacidad de terceros, discriminatorio o que de alguna manera pueda ofender a otros usuarios.
            </p>
            <br/>

            <h5>
                PROTECCION DE TUS DATOS
            </h5>

            <p>Peabody realiza un uso responsable de la información personal, protegiendo la privacidad de los usuarios que confiaron sus datos, tomando las medidas de seguridad necesarias. Sin embargo, no podemos garantizar de manera absoluta la seguridad de su información personal.
            </p>
            
            <p>El usuario siempre podrá solicitar un archivo exportado de sus datos personales y/o que sean borrados y eliminados de forma definitiva, salvo aquellos que sean estrictamente necesarios mantener por cuestiones administrativas, legales, o de seguridad.
            </p>
            <br/>

            <h5>
                EXCENCION DE RESPONSABILIDAD
            </h5>

            <p>Toda la información de la pagina web se facilita en su “estado actual”, y el consumidor asume el riesgo en cuanto a la calidad y rendimiento del sitio web.
            </p>
            
            <p>Por lo expuesto, PEABODY no otorga garantía o manifestación alguna de que: (i) la Página Web satisfaga sus necesidades, (ii) la Página Web esté disponible de forma ininterrumpida, correcta, segura y sin errores, o (iii) los resultados que puedan obtenerse del uso de esta (incluyendo cualquier información y materiales de esta Página Web) sean correctos, completos, exactos, fiables o que de cualquier otra forma satisfagan sus necesidades.
            </p>
            
            <p>PEABODY, sus accionistas, directores y/o empleados, no contraerán responsabilidad alguna por interrupciones u omisiones en los servicios de Internet, de red o de hospedaje y no garantizan que la Página Web o los servicios que dan cobertura a esta Página Web o las comunicaciones electrónicas enviadas por PEABODY estén libres de virus o cualquier otro elemento nocivo.
            </p>
            
            <p>Cualquier material que usted descargue u obtenga de cualquier forma a través de esta Página Web se hará por su cuenta y riesgo. Usted será el responsable exclusivo de cualquier daño causado a su sistema informático o de la pérdida de datos derivada de la descarga del referido material.
            </p>
            <br/>

            <h5>
                MODIFICACIÓN DE LAS CONDICIONES
            </h5>
            <p>PEABODY podrá modificar los presentes términos en cualquier momento. Los términos modificados serán de aplicación una vez hayan sido publicados. Se entenderá que acepta dichas modificaciones si continúa accediendo o utilizando la Página Web tras la publicación. 
            </p>
            
            <p>Le aconsejamos que revise regularmente todos los términos y condiciones aplicables. Otras páginas web de PEABODY podrán tener sus propias condiciones de utilización que serán exclusivamente aplicables a las mismas.
            </p>
            
            <p> PEABODY se reserva el derecho de interrumpir o realizar cambios o actualizaciones en la Página Web o su contenido en cualquier momento y sin previo aviso.
            </p>
            
            <p> Asimismo, PEABODY se reserva el derecho de restringir, rechazar o dar por finalizado el acceso de cualquier persona a la Página Web o a una parte de esta sin previo aviso y por cualquier motivo, a su exclusiva discreción y con aplicación inmediata.
            </p>
            <br/>

            <h5>
                ACTUALIZACION DE LA INFORMACION
            </h5>
            <p>La información suministrada en la pagina web puede estar desactualizada, y PEABODY no responderá por ello. Será responsabilidad de cada Servicio técnico Oficial (STO) actualizar el estado de reparación en el sitio web.
            </p>
        
            <p>Sin perjuicio de lo mencionado, el consumidor podrá solicitar información a través del contacto disponible al efecto que se detalla al final. 
            </p>
            <br/>

            <h5>
                DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL
            </h5>
            <p>Son propiedad de GOLDMUND S.A todos los derechos de autor y otros derechos de propiedad del Contenido facilitado por PEABODY y/o colaboradores, el software utilizado para manejar y publicar la Página Web , la recopilación de datos, y el orden, secuencia y adaptación de la misma. 
            </p>
            <br/>

            <h5>
                LEY APLICABLE. JURISDICCION
            </h5>
            <p>Los presentes términos y condiciones se rigen por las leyes de la Republica Argentina. Cualquier divergencia y/o duda en la interpretación de los presentes términos, se someterán a la jurisdicción de los tribunales nacionales ordinarios en lo comercial con asiento en la Ciudad Autonoma de Buenos Aires.
            </p>
            <br/>

            <h5>
                CUESTIONES VARIAS
            </h5>
            <p>A.	Nulidad parcial. Si alguna de las disposiciones de los presentes términos y condiciones es declarada nula o inexigible, será sustituida por la disposición válida y exigible que más se aproxime a la finalidad de la disposición originaria y el resto de las disposiciones seguirán siendo válidas.
            </p>
            <p>B.	Encabezamientos. A efectos del presente, los encabezamientos de las secciones de los Términos y Condiciones se incluyen exclusivamente para facilitar la consulta y no afectarán de forma alguna al sentido o la interpretación de estas.
            </p>
            <p>C.	Cuestiones no previstas. La utilización del sitio web implica la aceptación de los términos, así como las decisiones que, conforme a derecho, adopte PEABODY sobre cualquier cuestión no prevista en las mismas.
            </p>
        </div>
    )
}