import React, {useMemo, useEffect, useState, useRef} from 'react';
import {useTable, useSortBy, usePagination, useRowSelect, useGlobalFilter} from 'react-table';
import { NavLink, useNavigate } from 'react-router-dom';
import {GlobalFilter, StateFilter} from './globalfilter'
import left from '../images/left.png'
import next from '../images/next.png'
import {ExportTable} from './exportTable'
import {OrdenModal} from './modal'
import {ModalClave} from './ModalClave'
import {Actualizar} from './dbfirebase'

export const Table = ({showDetail, showComponents, showDetailorder, showDelete, listaOrdenes, source, settingcolumns}) =>{

    const navigate = useNavigate();

 
    // datos del producto seleccionado
    const [productInfo, setProductInfo] = useState({})

    // datos del pedido seleccionado
    const [documentInfo, setDocumentInfo] = useState({})

    //Guardamos los datos del pedido
    const [selecteddata, setSelecteddata] = useState([]);

    // datos del producto seleccionado
    const [productComponents, setProductComponents] = useState()

    //Puntaje actual
    const [selectedStar, setSelectedStar] = useState(null);

    const [selectedRowId, setSelectedRowId] = useState(null);


    //useMemo evita que en cada renderizado se vuelvan a llamar los datos
    const columns = useMemo(() => settingcolumns, [])
    const data = useMemo(() => listaOrdenes, [listaOrdenes])

    //Definimos la cantidad de filas que muestra la tabla.
    const numberofRows = 10

    //Referencia a la tabla
    const tableRef = useRef(null)

    useEffect(() =>{
        let lista = []
        selecteddata.map((i) =>{
            lista.push(i.CodProducto)
        })
        setProductComponents(...lista)
        
    },[]);
     
    //Setea el hook productInfo para renderizar un modal
    const productDetail = (rowData) => {
        setProductInfo(rowData)
        setDocumentInfo(rowData)
        
    }

    //funcion que ejecuta al clickear detalles
    const handleDetails = (rowData) => {
        productDetail(rowData)
    }

    const addDetailCol = (hooks) => {
        if (showDetail) {
            hooks.visibleColumns.push((columns) => [
                ...columns,
                {
                    id: "detalles",
                    Header: "Detalle",
                    Cell: ({row}) => (
                        <div>
                            {(source === "orden") &&
                                <a href="#" className="detailsLink" onClick={() => { handleDetails(row.original) }} data-bs-toggle="modal" data-bs-target="#Modal">
                                    Ver
                                </a>
                                // <ProductDetail  handleDetails={handleDetails} row={row} productInfo={productInfo}/>
                            }
                        </div>
            )}])
            
    }}

    const handleEstrellaClick = async(event, id) => {
        // Obtiene el valor seleccionado del input de radio
        const valor = event.target.value;
      
        // Crea un objeto con los datos que deseas almacenar en Firebase
        const data = {
          Calificacion: valor,
          Fecha_calificacion: new Date().toISOString(),
          id: id
        };
        // Envía los datos a Firebase
        const newstate = await Actualizar(data);

        setSelectedRowId(id);
    }

    const handleRandomNumber = async(event, id) => {
        // Obtiene el valor seleccionado del input de radio
        const valor = event.target.value;
      
        // Crea un objeto con los datos que deseas almacenar en Firebase
        const data = {
          Calificacion: valor,
          Fecha_calificacion: new Date().toISOString(),
          id: id
        };
        // Envía los datos a Firebase
        const newstate = await Actualizar(data);

        setSelectedRowId(id);
    }

    //Columna de detalle
    const addrankingcol = (hooks) => {
        if (showDetail) {
            hooks.visibleColumns.push((columns) => [
                ...columns,
                {
                    id: "Experiencia",
                    Header: "Calificame",
                    Cell: ({row}) => (
                        <div>
                            {(row.original.Estado === "Retirar" || row.original.Estado === "Despachado") &&
                            <div >
                                <p className="clasificacion sm m-auto" >
                                    <input id={`radio1-${row.original.Id}`} type="radio" name={`estrellas-${row.original.Id}`} value="5" defaultChecked={row.original.Calificacion === "5"||selectedStar === "5"} onClick={(e) => handleEstrellaClick(e, row.original.Id)}/>
                                    <label htmlFor={`radio1-${row.original.Id}`}>★</label>
                                    <input id={`radio2-${row.original.Id}`} type="radio" name={`estrellas-${row.original.Id}`} value="4" defaultChecked={row.original.Calificacion === "4"||selectedStar === "4"} onClick={(e) => handleEstrellaClick(e, row.original.Id)}/>
                                    <label htmlFor={`radio2-${row.original.Id}`}>★</label>
                                    <input id={`radio3-${row.original.Id}`} type="radio" name={`estrellas-${row.original.Id}`} value="3" defaultChecked={row.original.Calificacion === "3"||selectedStar === "3"} onClick={(e) => handleEstrellaClick(e, row.original.Id)}/>
                                    <label htmlFor={`radio3-${row.original.Id}`}>★</label>
                                    <input id={`radio4-${row.original.Id}`} type="radio" name={`estrellas-${row.original.Id}`} value="2" defaultChecked={row.original.Calificacion === "2"||selectedStar === "2"} onClick={(e) => handleEstrellaClick(e, row.original.Id)}/>
                                    <label htmlFor={`radio4-${row.original.Id}`}>★</label>
                                    <input id={`radio5-${row.original.Id}`} type="radio" name={`estrellas-${row.original.Id}`} value="1" defaultChecked={row.original.Calificacion === "1"||selectedStar === "1"} onClick={(e) => handleEstrellaClick(e, row.original.Id)}/>
                                    <label htmlFor={`radio5-${row.original.Id}`}>★</label>
                                </p>
                            </div>
                            }
                        </div> 
                    )
                }
        ]);
    }}

    const addClave = (hooks) => {
        if (showDetail) {
            hooks.visibleColumns.push((columns) => [
                ...columns,
                {
                    id: "Claves",
                    Header: "Claves",
                    Cell: ({row}) => {
                        if (row.original.Estado === "Retirar" || row.original.Estado === "Despachado") {
                            return (
                              <div>
                                <a
                                  href="#"
                                  className="detailsLink"
                                  onClick={() => {
                                    handleDetails(row.original);
                                  }}
                                  data-bs-toggle="modal"
                                  data-bs-target="#Modal1"
                                >
                                  Clave
                                </a>
                              </div>
                            );
                          } else {
                            return null; // No se renderiza el enlace
                          }
            }}])    
    }}
   
    //Configuracion de las funciones de la tabla
    const tableInstance = useTable({
        columns,
        data,
        initialState : {pageSize: numberofRows}},
        useGlobalFilter, 
        useSortBy, 
        usePagination, 
        useRowSelect,
        addrankingcol,
        addDetailCol,
        addClave
        
        );
    
    //Seleccion de las instancias que requiere la tabla
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        state,
        prepareRow,
        selectedFlatRows,
        pageCount: controlledPageCount,
        setGlobalFilter,
    } = tableInstance

    const {pageIndex, globalFilter} = state

    //renderizado de tabla con filtros y checkbox
    return (
        <div>
            {/* <h1>{source}</h1> */}
                {/* <ProductDetail productInfo={productInfo} />
                <DocumentDetail DocInfo={documentInfo} /> */}
            <OrdenModal data = {documentInfo}/>
            <ModalClave data = {documentInfo}/>
            <div className="d-flex justify-content-evenly contenedor">
                <div className="col-6">
                    <GlobalFilter filter = {globalFilter} setFilter = {setGlobalFilter}/>
                </div>
                <div className="col-4">
                    <StateFilter filter = {globalFilter} setFilter = {setGlobalFilter}/>
                </div>
                {/* <div className="col-2 d-flex justify-content-center">
                    <ExportTable tablereference={tableRef} />
                </div> */}
            </div>
            <br/>
            <div className = "shadow-lg p-3 mb-5 bg-white rounded" style={{ overflowX: "auto" }}>
                
            <table className="table table-hover table-sm table-bordered table-responsive-sm" 
                    {...getTableProps()} ref={tableRef}>
                <thead className="thead-light">
                    {headerGroups.map((headerGroup) =>(
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                    style={{
                                        borderBottom: 'solid 1px black',
                                        color: 'black'}}>
                                    {column.render('Header')}
                                    <span>{column.isSorted? column.isSortedDesc? '▼': '▲':''}</span></th>
                                ))}
                        </tr>    
                    ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {... row.getRowProps()} onInput={() => {setSelecteddata(Object.values(selectedFlatRows.map((row) => row.original)))}} style={{verticalAlign: 'middle', backgroundColor: row.original.Estado === 'Finalizado'? '#C4EEB1': row.original.Estado === 'Reparado'? '#ffd966':'white'}}> 
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                                    })}
                                
                            </tr>    
                        );
                    })}
                </tbody>
            </table>
            </div>
            {/* Control de paginacion */}
            <div className="col text-center">
                {/* Atras */} 
                <button className="btn btn-outline-dark mx-1" 
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                            style={{margin: '0 auto', border:'none',backgroundColor: '#ffffff'}}>
                            <img src={left} width="40rem" height="40rem"/>
                </button>
                    {/* texto Page 1 of 1 ... */}
                    <span>
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {controlledPageCount}
                        </strong>{' '}
                    </span>
                {/* Adelante */}   
                <button className="btn btn-outline-dark" 
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                            style={{margin: '0 auto', border:'none',backgroundColor: '#ffffff'}}>
                            <img src={next} width="40rem" height="40rem"/>
                </button>
                
            </div>
            <br/>
            {/* vertabla? <div id='btnLoading' className="spinner-border spinner-border-sm hideLoad" role="status" />  */}
        </div>
    )
}