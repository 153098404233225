import React, {useState} from 'react';
import {ModalClaveresponsive} from './ModalClaveresponsive'

export const ResponsiveTable = (data) => {
    
    const [busqueda, setBusqueda] = useState('');
    const [item, setItem] = useState('')

    const accessorsFiltrados = [
        "TimeStamp",
        "iddoc",
        "Cliente",
        "CuitDni_cli",
        "Num_Os",
        'Articulo',
        'Garantia',
        'Estado',
        'Id',
        'Titular',
        'Taller',
        // Agrega otros accessors aquí
      ];
      
      const nombresClaves = {
        "TimeStamp": "Fecha",
        "iddoc": "N° Orden",
        "Cliente": "Cliente",
        "CuitDni_cli": "Cuit/Dni",
        "Num_Os": "Num. Os taller",
        'Articulo': "Articulo",
        'Garantia': "Garantia",
        'Estado': "Estado",
        'Id':'Id',
        'Titular':'Titular',
        'Taller': 'Taller'
        // Agrega otros nombres aquí
      };
      

    const datosFiltrados = data.data
      .filter((dato) => {
        return accessorsFiltrados.some(accessor => {
          if (dato.hasOwnProperty(accessor)) {
            const valor = dato[accessor].toString().toLowerCase();
            return valor.includes(busqueda.toLowerCase());
          }
          return false;
        });
      })
      .map((dato) => {
        const datoFiltrado = {};
        accessorsFiltrados.forEach((accessor) => {
          if (dato.hasOwnProperty(accessor)) {
            if (accessor === "TimeStamp") {
              datoFiltrado[nombresClaves[accessor]] = new Date(dato[accessor] * 1000).toLocaleString().split(',')[0];
            } else if (accessor === "Garantia") {
              datoFiltrado[nombresClaves[accessor]] = dato[accessor] ? "SI" : "NO";
            } else {
              datoFiltrado[nombresClaves[accessor]] = dato[accessor];
            }
          }
        });
      return datoFiltrado;
    });
      
    const camposNoDeseados = ["Id"];

    return (
      <div>
        
        <input
          style={{marginBottom:"1rem"}}
          type="text"
          placeholder="Buscar..."
          value={busqueda}
          onChange={(e) => setBusqueda(e.target.value)}
        />
        <table className="table table-striped">
          <tbody>
            {datosFiltrados.map((item, index) => (
              <React.Fragment key={`row-${index}`}>
                {Object.entries(item)
                .filter(([clave, valor]) => !camposNoDeseados.includes(clave))
                .map(([clave, valor]) => (
                  <tr key={`data-${clave}`}>
                    <td>{clave}</td>
                    <td>{JSON.stringify(valor)}</td>
                  </tr>
                ))}
                {index < datosFiltrados.length  && (
                  <React.Fragment>
                    <tr key={`button-${index}`}>
                      <td colSpan="2">
                        <a
                          href="#"
                          className="detailsLink"
                          data-bs-toggle="modal"
                          data-bs-target="#Modal2"
                          onClick={() => {
                            setItem(item);
                          }}
                        >
                          Generar clave
                        </a>
                      </td>
                    </tr>
                    <tr key={`separator-${index}`}>
                      <td colSpan="2">----------------------------------------</td>
                    </tr>
                </React.Fragment>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      <ModalClaveresponsive data={item} />
    </div>
  );
}

