import React from 'react'
import {Link} from 'react-router-dom' //Link nos permite crear un acceso a otras componentes
import ServicesPostventaLogo from '../images/ServicesPostventaLogo.PNG'
import {Showafterloged} from './hidden'

export const Navbar = () => (
    <nav className="navbar navbar-expand-sm navbar-light navigation"
                    style={{width:'90%', 
                    backgroundColor: '#ffffff', textAlign: 'center',
                    margin: '0 5%'}}>
        
        {/*Link que lleva a la raiz con el nombre de la pagina*/}
        <Link className="navbar-brand mx-4" to="/"><img src={ServicesPostventaLogo} width="210" height="60"></img></Link>
        
        {/*Agregamos un boton de navegacion*/}
        <button className="navbar-toggler" 
                type="button" 
                data-bs-toggle="collapse" 
                data-bs-target="#navbarNav" 
                aria-controls="navbarNav" 
                aria-expanded="false" 
                aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"/>
        </button>

        {/* <div className='col'> */}
          <Showafterloged/>
        {/* </div> */}
      </nav>
)