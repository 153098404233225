import React, {useEffect, useState, useRef} from 'react'
import {ActualizarFirma, GetOrder} from './dbfirebase'
import CryptoJS from 'crypto-js'

export const ModalClave = (row) => {
    
    const[motivo, setMotivo] = useState()
    const[sign, setSign] = useState(0)
    const[ciphertext, setCiphertext] = useState()

    const textarea = useRef(null);
    const OrderRef = useRef(null)
    
    const closeModal = () => {
        const modal = document.getElementById('Modal1');
        const backdrop = document.querySelector('.modal-backdrop');
        
        if(OrderRef.current.classList.contains('show')){           
            modal.classList.remove('show');
            modal.style.display = 'none'
            document.body.classList.remove('modal-open');
            modal.classList.add('show');
        }
        if (backdrop) {
            backdrop.remove();
        }
        document.body.style.overflow = 'auto';
        
    };

    const handleCloseModal = () => {
         closeModal()
    };

    // window.history.pushState({}, '', '');

    useEffect(() => {

        const handlePopstate = () => {
            if (OrderRef.current.classList.contains('show')) {
                closeModal()
            }
        };
      
        window.addEventListener('popstate', handlePopstate);
      
        return () => {
            window.removeEventListener('popstate', handlePopstate);
        };
        
    },[]);

    // const dataId = async () =>{
    //     const textareaValue = textarea.current.value;
    //     const newstate = await Actualizar('Reparado', {Fecha_finalizado: new Date().toLocaleString()},textareaValue);
    //     row.setUpdate(true)
    //     closeModal()
    // }

    const generateRandomNumber = async() => {
        const min = 1000; // El número mínimo de 4 cifras
        const max = 9999; // El número máximo de 4 cifras
        const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
        setSign(randomNumber);
        const cifrado = CryptoJS.AES.encrypt(JSON.stringify(row.data.iddoc), randomNumber.toString()).toString()
        setCiphertext(cifrado)
        const newstate = await ActualizarFirma(cifrado, row.data.Id);
        //closeModal()
    };

    useEffect(()=>{
        setSign(0)
    },[row.data.Articulo])
    
    return (
        <div className="modal"
            id="Modal1"
            tabIndex={-1}
            aria-labelledby="Modal1"
            aria-hidden="true"
            style={{marginBottom:'20px' ,marginTop:'20px', overflow: "auto"}}ref={OrderRef}>
            <div className="modal-dialog modal-fullscreen-lg-down modal-lg">
                <div className='container modal-content' style={{ overflow: "auto",height:'25rem'}} >
                    <button type="button" className='btn-close' onClick={()=>handleCloseModal()}></button>
                    <h2 style={{marginTop:'40px',marginBottom:'20px'}}>Orden de servicio</h2>
                        <div className=' col-12 d-flex justify-content-center'>
                            <div className="row">
                                <p className='col-lg-6 d-flex mb-1'>Responsable: {row.data.Titular}</p>
                                <p className='col-lg-6 d-flex mb-1'>Razon social: {row.data.Taller}</p>
                                <p className='col-lg-6 d-flex mb-1'>Articulo: {row.data.Articulo}</p>
                                <p className='col-lg-6 d-flex mb-1'>Categoria: {row.data.Categoria}</p>
                                <p className='col-lg-6 d-flex mb-1'>Cliente: {row.data.Cliente}</p>
                            </div>  
                        </div>
                        <div className=' col-12 d-flex justify-content-center' style={{marginBottom:'20px' ,marginTop:'20px', overflow: "auto"}}>
                            <p style={{fontSize:'2rem'}}>Clave unica para firmar la orden de retiro: {sign}</p> 
                        </div>
                        <div className=' col-12 d-flex justify-content-center'> 
                            <button className="btn btn-secondary" style={{height:'3rem', width:'10rem',marginTop:'10px',marginBottom:'20px' }} onClick={()=>{generateRandomNumber()}}>
                                Generar clave
                            </button>
                        </div>
                </div>
            </div>
        </div>
    );
  };