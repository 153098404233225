import React from 'react';

export const GlobalFilter = ({filter, setFilter}) => {
    return (
        <span>
            Buscar: {' '}
            <input value = {filter || ''} onChange = {(e) => {setFilter(e.target.value)}} style={{width: '15rem'}}/>
        </span>
    )
}

export const StateFilter = ({filter, setFilter}) => {
    return (
        <div className="btn-group dropright">
            <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Filtrar por estado:
            </button>
            <div className="dropdown-menu">
                <button className='btn btn-light' onClick = {() => {setFilter('Pendiente')}} style={{width: '10rem'}}>Pendientes</button>
                <button className='btn btn-light' onClick = {() => {setFilter('Reparando')}} style={{width: '10rem'}}>Reparando</button>
                <button className='btn btn-light' onClick = {() => {setFilter('')}} style={{width: '10rem'}}>Quitar filtro</button>
            </div>
        </div>
    )
}