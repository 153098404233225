export const columnsOrdenes = [
    {
        Header: "Fecha",
        accessor: "TimeStamp",
        Cell: ({value}) => {return(new Date(value.seconds * 1000).toLocaleString())}
    },
    {
        Header: "N° Orden",
        accessor: "iddoc"
    },
    {
        Header: "Taller",
        accessor: "Taller"
    },
    {
        Header: "Cuit/Dni",
        accessor: "CuitDni_cli"
    },
    {
        Header: "Categoria",
        accessor: "Categoria"
    },
    {
        Header: "Articulo",
        accessor: "Articulo"
    },
    {
        Header: "Estado",
        accessor: "Estado",
    },
    {
        Header: "En garantia",
        accessor: "Garantia",
        Cell: ({value}) => {if(value){return('SI')}else{return('NO')}}
    },
]

export const columnsTalleres = [
    {
        Header: "Taller",
        accessor: "Taller",
    },
    {
        Header: "Cuit",
        accessor: "Cuit"
    },
    {
        Header: "Direccion",
        accessor: "Direccion"
    },
    {
        Header: "Localidad",
        accessor: "Localidad"
    },
    {
        Header: "Provincia",
        accessor: "Provincia"
    },
    {
        Header: "Telefono",
        accessor: "Telefono"
    },
    {
        Header: "Titular",
        accessor: "Titular",
    },
]