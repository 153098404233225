import React,  { useState }  from 'react'
import google from '../images/google.png'
import {useNavigate} from "react-router-dom";
import {useAuth} from "../context/authcontext";
import eyeIco from '../images/eyeduotone.png';
import { ToastContainer, toast } from 'react-toastify';

export const Login = () =>{

    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(false);
    const [mayuscula, setMayuscula] = useState(false)
    const {signInWithGoogle, signup, signin} = useAuth();

    const [user, setUser] = useState({
        email : "",
        password : "",
    });

    // estado para la visibilidad del password 
    const [passwordType, setPasswordType] = useState("password");

    //Funcion que maneja el checkbox
    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    }

    //Funcion que captura los datos de los inputs y los coloca en el hook
    const handlechange = ({target: {name, value}}) => 
        setUser({...user, [name]: value});

    // "e" es el evento que vamos a capturar de la escritura en el formulario
    const handlesubmit = async (e) => {
        e.preventDefault();
        if(isChecked){
            try {
                await signup(user.email, user.password);
                navigate("/ordenes");
            }
            catch (error) {
                toast.error(error.message,{
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true
                })   
            }
        }else{
            toast.error('Debe acceptar los terminos y condiciones',{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true
            })  
        }
    };

    const handlesubmit2 = async (e) => {
        e.preventDefault();
        if(isChecked){
            try {
                await signin(user.email, user.password);
                navigate("/ordenes");
            }
            catch (error) {
                toast.error(error.message,{
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true
                })   
            }
        }else{
            toast.error('Debe acceptar los terminos y condiciones',{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true
            })  
        }
    };

    const SigninGoogle = async () => {
        if(isChecked){
            try {
                await signInWithGoogle()
                navigate("/ordenes");
            }
            catch (error) { 
            }
        }else{
            toast.error('Debe acceptar los terminos y condiciones',{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true
            })  
        }   
    };

    //Funcion que envia mail para resetear la contraseña y muestra un popup con un mensaje
    const triggerResetEmail = async () => {
        let messageOk = "Email enviado, revise su casilla (spam)"
        let messageError = "Dirección de correo no valida"
        try {
            //await sendPasswordResetEmail(auth, user.email);
            //notify_success()
        } catch (error) {
            //setError(error.message);
        }
    };

    //funcion para cambiar visibilidad de la contraseña
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
        } else {
            setPasswordType("password")
        }
    }

    const termycond = () =>{
        navigate('/terminosycondiciones')
    }

    function handleClick(e) {
        e.preventDefault();
        e.currentTarget.classList.add('show'); // Agrega la clase 'show' al elemento actual
      }

    const isCapsLockOn = (event) => {
        const caps = event.getModifierState && event.getModifierState('CapsLock');
        setMayuscula(caps)
        //return caps;
    }

    document.addEventListener('mouseenter',isCapsLockOn);
    document.addEventListener('keydown',isCapsLockOn);

    return(
        <div className="row">
            <ToastContainer />
            <div className='container d-flex justify-content-center'>
                <div className="col-md-4 p-3 mb-5 bg-white rounded">
                    <div className = 'row d-flex justify-content-center'>
                    
                    <div className="card" style={{marginTop: '70px'}}>
                        <div className="card-header" style={{ '--bs-card-cap-bg': 'white' }}>
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item">
                                    <button className="nav-link active" id="description-tab" data-bs-toggle="tab" data-bs-target="#description" type="button" role="tab" aria-controls="description" aria-selected="true">Registrarse</button>
                                </li>
                                <li className="nav-item">
                                    <button className="nav-link" id="history-tab" data-bs-toggle="tab" data-bs-target="#history" type="button" role="tab" aria-controls="history" aria-selected="false">Ingresar</button>
                                </li>
                            </ul>
                        </div>
                            <div className="card-body">
                                <div className="tab-content mt-3">
                                    <div className="tab-pane active" id="description" role="tabpanel" aria-labelledby="description-tab">  
                                        <div className='row d-flex justify-content-center'>
                                            <h5 className='col-12 d-flex justify-content-center text-center'>
                                                <strong>REGISTRATE</strong>
                                            </h5>
                
                                            <button onClick = {SigninGoogle} 
                                                    className = "form-control d-flex justify-content-center"
                                                    style={{height: '40px', width: '20rem', marginTop: '30px'}} 
                                                    autoFocus>
                                                <p className= 'd-flex justify-content-start' style={{height: '2rem', width: '40rem'}}>
                                                <img src={google} width="25rem" height="25rem" style={{marginRight: '25px'}}/>
                                                    Continuar con Google
                                                </p>       
                                            </button>
                                        </div>
                                        <div className='row d-flex justify-content-center'>  
                                            <form onSubmit={handlesubmit} style={{width: '20rem'}}>
                                                <p className='d-flex justify-content-center text-center' style={{marginTop: '30px'}}>O registrate con tu correo electronico:</p>
                                                    <input 
                                                        type = "email"
                                                        name = "email"
                                                        onChange = {handlechange} 
                                                        className = "form-control"
                                                        placeholder = "Email"
                                                        id = "email"
                                                        autoFocus
                                                        />
                                            
                                                <div className="passwordContainer mb-4 d-flex position-relative">
                                                    <input 
                                                        type = {passwordType}
                                                        name = "password"
                                                        onChange = {handlechange} 
                                                        className = "form-control"
                                                        placeholder = "Contraseña"
                                                        id = "password"
                                                        style={{marginTop: '15px'}}
                                                        />
                                                        <img className="eyeIcon mt-3"
                                                            onClick={togglePassword}
                                                            src={eyeIco}
                                                            alt="o"/>
                                                    
                                                </div>
                                                <p style={{fontSize:'15px'}}>{mayuscula?'Act. Mayusculas':''}</p>
                                                <button className="btn btn-primary"
                                                        style={{ width: "100%"}}
                                                        // disabled = {true}
                                                        >
                                                    Registrar
                                                </button>
                                                <div className ='form-check' style={{marginTop: '15px'}}>
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={isChecked} onChange={handleCheckboxChange}/>
                                                    <p>
                                                        He leído y acepto las
                                                        <a href="#" onClick={() => termycond()}> Politicas de Privacidad </a>
                                                        y los
                                                        <a href="#" onClick={() => termycond()}> Terminos y condiciones</a>
                                                    </p>
                                                </div>
                                                <a className="passRecovery"
                                                    href="#" 
                                                    onClick={triggerResetEmail}
                                                    style={{marginTop: '15px'}}>
                                                    ¿Olvidaste tu contraseña?
                                                </a>
                                            </form>
                                        </div>    
                                    </div>
                                    <div className="tab-pane" id="history" role="tabpanel" aria-labelledby="history-tab">
                                        <div className='row d-flex justify-content-center'>
                                                <h5 className='col-12 d-flex justify-content-center text-center'>
                                                    <strong>INICIA SESIÓN</strong>
                                                </h5>
                    
                                                <button onClick = {SigninGoogle} 
                                                        className = "form-control d-flex justify-content-center"
                                                        style={{height: '40px', width: '20rem', marginTop: '30px'}} 
                                                        autoFocus>
                                                    <p className= 'd-flex justify-content-start' style={{height: '2rem', width: '40rem'}}>
                                                    <img src={google} width="25rem" height="25rem" style={{marginRight: '25px'}}/>
                                                        Continuar con Google
                                                    </p>       
                                                </button>
                                            </div>
                                        <div className='row d-flex justify-content-center'>
                                            <form onSubmit={handlesubmit2} style={{width: '20rem'}}>
                                                    <p className='d-flex justify-content-center text-center' style={{marginTop: '30px'}}>O inicia sesión con tu correo electronico:</p>
                                                        <input 
                                                            type = "email"
                                                            name = "email"
                                                            onChange = {handlechange} 
                                                            className = "form-control"
                                                            placeholder = "Email"
                                                            id = "email"
                                                            autoFocus
                                                            />
                                                
                                                    <div className="passwordContainer mb-4 d-flex position-relative">
                                                        <input 
                                                            type = {passwordType}
                                                            name = "password"
                                                            onChange = {handlechange} 
                                                            className = "form-control"
                                                            placeholder = "Contraseña"
                                                            id = "password"
                                                            style={{marginTop: '15px'}}
                                                            />
                                                            <img className="eyeIcon mt-3"
                                                                onClick={togglePassword}
                                                                src={eyeIco}
                                                                alt="o"/>
                                                    </div>
                                                    <p style={{fontSize:'15px'}}>{mayuscula?'Act. Mayusculas':''}</p>
                                                    <button className="btn btn-primary"
                                                            style={{ width: "100%"}}
                                                            // disabled = {true}
                                                            >
                                                        Iniciar Sesión
                                                    </button>
                                                    <div className ='form-check' style={{marginTop: '15px'}}>
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={isChecked} onChange={handleCheckboxChange}/>
                                                        <p>
                                                            He leído y acepto las
                                                            <a href="#" onClick={() => termycond()}> Politicas de Privacidad </a>
                                                            y los
                                                            <a href="#" onClick={() => termycond()}> Terminos y condiciones</a>
                                                        </p>
                                                    </div>
                                                    <a className="passRecovery"
                                                        href="#" 
                                                        onClick={triggerResetEmail}
                                                        style={{marginTop: '15px'}}>
                                                        ¿Olvidaste tu contraseña?
                                                    </a>
                                            </form>
                                        </div>    
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        </div>
    )
}